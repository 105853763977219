:root {
  //Colors
	--primary-color: #006280;
	--primary-outline-color: #2a90af;
	--primary-hover-color: #66c0e1;
	--accent-color: #c8ad00;
	--accent-outline-color: #ffdd3a;
	--accent-hover-color: #ffff70;
	--success-color: #63ce6b;
	--error-color: #ff8484;
	--info-color: #3bc7e3;
	--warning-color: #fff0a8;
	--gray-color: #2B2B2B;
	--canvas-color: #0a0a0a;
	--backgrounds-color: #131313;
	--items-color: #191919;
	--items-hover-color: #555454;
	--white-color: #555454;
	--background-color: #0A0A0A;
	--secondary-color: #707070;

	// Font family
	--font-family-primary: "QuickSand";
	--font-family-bold: "QuickSand Bold";
	--font-family-italic: "QuickSand Italic";
	--font-family-secondary: "Open Sans";
}

.flex-1{
  flex: 1;
}
.cursor-pointer{
  cursor: pointer;
  transition: all 300ms ease;
  &:hover{
    opacity: .7;
  }
}

/* Ripple Effect */

.ripple-effect {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple-effect:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple-effect:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}


.ReactCollapse--collapse {
  max-width: 800px;
  border: 1px solid rgba(3, 169, 244, 0.3);
  border-radius: 10px;
  background-color: rgba(100, 255, 100, 0.1);
  transition: height 500ms;
}

.ReactCollapse--content {
}

//rc-slider
.rc-slider-mark-text-active,
.rc-slider-mark-text{
  width: 60px;
}
.rc-slider-dot{
  width: 12px;
  height: 12px;
  bottom: -4px;
  margin-left: -6px;
}
.rc-slider-track,
.rc-slider-dot-active {
  border-color: #006280;
  background-color: #006280;
}
.rc-slider-handle,
.rc-slider-handle:hover,
.rc-slider-handle-click-focused:focus
{
  border-color: #006280;
}
.rc-slider-handle{
  width: 18px;
  height: 18px;
  margin-top: -7px;
  background: #006280;
}
.rc-slider-dot-active {
  background-color: #006280;
}

.brand-tooltip{
  width: 240px;
  opacity: 1!important;
  text-align: left!important;
  display: flex;
  justify-content: flex-start;
  padding: 8px 12px!important;
  & > *{
    text-align: left!important;
  }
}

.player-tools{
  opacity: 1!important;
  text-align: left!important;
  display: flex;
  justify-content: flex-start;
  padding: 8px 12px!important;
  & > *{
    text-align: left!important;
  }
}
iframe{
  left: 10px!important;
}


.color-secondary{
  color: #707070;
}

.cropper-container{
  // width: 100%!important;
  // height: 100%!important;
  .cropper-canvas{
    // width: 100%!important;
    // height: 100%!important;
    img {
      display: block;
    }
  }
}

.ReactModal__Content{
  background: #131313!important;
}


/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: white !important;
}